<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <b-link class="brand-logo">
        <b-img
          :src="require('@/assets/images/logo/logo.svg')"
          alt="Surfe logo"
          style="max-width:150px"
        />
      </b-link>

      <!-- Login-->
      <b-col
        lg="6"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="8"
          xl="6"
          class="px-xl-2 mx-auto"
        >

          <template v-if="extLogin">
            <b-card-title
              title-tag="h2"
              class="mb-2_5 font-weight-bolder font-mono"
            >
              Connect to Surfe
            </b-card-title>
            <b-card-text class="mb-7 text-muted">
              Access your dashboard to manage your account.
            </b-card-text>

            <b-button
              :disabled="waitForLogin"
              variant="primary"
              block
              @click="logIn"
            >
              <b-spinner
                v-if="waitForLogin"
                class="mx-2"
                small
              />
              <span v-else>
                Login
              </span>
            </b-button>
          </template>

          <template v-else>
            <div v-if="!magicLinkSent">
              <b-card-title
                title-tag="h2"
                class="mb-2_5 font-weight-bolder font-mono"
              >
                Connect to Surfe
              </b-card-title>
              <b-card-text class="mb-7 text-muted">
                Connect using the email address you use in your CRM. You will receive a link in your inbox.
              </b-card-text>

              <b-form-input
                v-model="email"
                class="mb-4_5"
                placeholder="Enter your email address"
                type="email"
                required
                @input="validateEmail"
              />

              <div class="text-right">
                <b-button
                  block
                  class="mt-1"
                  type="submit"
                  variant="terciary"
                  :disabled="waitForMagicLink || !validEmail"
                  @click="sendMagicLink"
                >
                  <b-spinner
                    v-if="waitForMagicLink"
                    class="mx-2"
                    small
                  />
                  <span v-else>
                    <feather-icon
                      icon="LinkIcon"
                      class="mr-2"
                    />
                    Send magic link
                  </span>
                </b-button>
              </div>
            </div>

            <div v-else>
              <h2>
                Check your mailbox!
              </h2>

              <p class="mt-3">
                We have just sent you an email to <b>{{ email }}</b>, click on the link to log in.
              </p>

              <p class="text-muted">
                With passwordless login, you do not need to remember a password.<br></p>
            </div>
          </template>
        </b-col>
      </b-col>

      <!-- Cover -->
      <b-col
        lg="6"
        class="d-none d-lg-flex align-items-center justify-content-center p-5 bg-primary bg-lighten-5"
      >
        <div
          class="d-flex position-relative"
          style="width: 477px; height: 312px;"
        >
          <b-img
            fluid
            :src="require('@/assets/images/wave-orange.svg')"
            alt="Login illustration"
            class="position-absolute"
            style="left: 4.61%; right: 4.61%; top: 36.96%"
          />
          <b-img
            fluid
            :src="require('@/assets/images/login-illustration.png')"
            :srcset="require('@/assets/images/login-illustration.png') + ' 1x, ' + require('@/assets/images/login-illustration@2x.png') + ' 2x'"
            alt="Login illustration"
            class="position-absolute position-top-0 position-right-0 position-left-0 position-bottom-0"
          />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import {
  BRow, BCol, BLink, BFormInput, BCardText, BCardTitle, BImg, BButton, BSpinner,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { trackEvent } from '@core/utils/tracking'
import { sleep } from '../utils'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormInput,
    BCardText,
    BCardTitle,
    BImg,
    BButton,
    BSpinner,
  },
  data() {
    return {
      email: '',
      magicLinkSent: false,
      extLogin: false,
      waitForLogin: false,
      waitForMagicLink: false,
      validEmail: false,
    }
  },
  async mounted() {
    localStorage.clear()

    // Ask extension for token
    for (let i = 0; i < 15; i++) {
      window.postMessage('injectToken', '*')
      await sleep(100)
      if (localStorage.getItem('key')) {
        this.extLogin = true
        break
      }
    }
  },
  methods: {
    async logIn() {
      this.waitForLogin = true

      // Fetch account info
      try {
        await this.$store.dispatch('api/loadAccount')
        await this.$store.dispatch('api/loadAll')
        await trackEvent({ type: 'dashboard-signed_in' })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'An error occurred',
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
        this.waitForLogin = false
        throw error
      }

      setTimeout(() => {
        const { redirect } = this.$route.query
        this.$router.push(redirect ? window.atob(redirect) : '/')
      }, 800)
    },
    async sendMagicLink() {
      this.waitForMagicLink = true
      try {
        await this.$store.dispatch('api/sendMagicLink', { email: this.email })
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Magic link sent',
            icon: 'SendIcon',
            variant: 'success',
          },
        })
        await trackEvent({ type: 'dashboard-magic_link_sent', props: { email: this.email } })
        this.magicLinkSent = true
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'An error occurred',
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
      }
      this.waitForMagicLink = false
    },
    validateEmail() {
      this.validEmail = String(this.email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        )
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
